<template>
  <div>
    <Header index="0"/>
    <div class="container">
      <!-- 左侧内容显示 -->
      <div class="container-left">
        <el-divider content-position="left" style="height: 20px;background-color: orange">客服详情
        </el-divider>
        <el-card>
          <el-row>
            <er-col :span="24">
              <el-row>
                <el-col :span="12">
                  <p style="font-size: 60px;float: left;margin: 0 10px"
                     class="iconfont icon-yonghu"></p>
                  <div style="float: left">
                    <p>一念无明</p>
                    <el-tag>游戏代购</el-tag>
                    <el-tag>游戏问题</el-tag>
                    <el-tag>退款指导</el-tag>
                  </div>
                </el-col>
                <el-col :span="12">
                  <el-button type="success" @click="fufei(index)" style="float: right">付费
                  </el-button>
                </el-col>
              </el-row>
            </er-col>
            <er-col :span="24">
              <el-card style="margin: 25px 10px">
                <p>
                  SteamPY是为Steam玩家提供的游戏交易市场，
                  可以出售自己的Steam余额，低价购买Steam游戏，提供Steam游戏礼物代购，查询Steam余额，查看Steam额度等。
                </p></el-card>
            </er-col>
          </el-row>
        </el-card>
        <el-divider content-position="left" style="height: 20px;background-color: orange">玩家评价</el-divider>
        <el-row>
          <el-col span="12" v-for="i in 5" :key="i">
            <el-card>
              <p style="font-size: 60px;float: left;margin: 0 10px" class="iconfont icon-yonghu"></p>
              <p>客服很给力,成功解决游戏问题</p>
            </el-card>
          </el-col>
        </el-row>
      </div>

      <!-- 右侧内容显示 -->
      <Swiperlist/>

      <!-- 弹窗 -->
    </div>
  </div>
</template>

<script>
import Swiperlist from "@/components/Swiperlist/index";

export default {
  mounted() {
  },
  data() {
    return {}
  },
  methods: {
    fufei(index) {
      this.$router.push({
        name: "fufei",
        query: {
          index,
        },
      });
    },
  },
  components: {
    Swiperlist,
  },
};
</script>

<style scoped>
@import url("//at.alicdn.com/t/font_2824970_ph5iej5lot.css");

.container {
  width: 8.163265rem /* 1200/147 */;
  margin: 0 auto;
  margin-top: 0.47619rem /* 70/147 */;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.container-left {
  width: 900px;
  background-color: white;
  margin-bottom: 20px;
  font-size: 14px;
}

.container-right > img {
  margin: 5px 0;
}

.left-content {
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 10px;
}


.dialog-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.dialog-content {
  width: 100%;
  height: 500px;
  background-color: rgba(255, 255, 0, 0.1);
  overflow-y: scroll;
  scroll-behavior: smooth;
  overscroll-behavior: contain;
}


.dialog-content-left {
  color: black;
  background-color: rgb(240, 240, 240);
  text-align: center;
  max-width: 200px;
  margin: 10px 0;
  padding: 10px 10px;
  border-radius: 5px;
}

.el-divider--horizontal {
  height: 19px;
}

.el-divider--horizontal {
  background-color: orange;
}

.el-divider__text.is-left {
  left: 20px;
  transform: translateY(0%);
}
</style>
